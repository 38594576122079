.header-original {
    margin: 0 32px 0 32px;
    justify-content: space-between !important;
    max-width: 100%;
}

.header-right {
    margin-left: auto;
    display: flex;
    flex-direction: row;
}
