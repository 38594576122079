@import 'node_modules/nhsuk-frontend/packages/core/all';

.headerButton {
    display: block;
    margin-bottom: 0;
    box-shadow: none;
    background-color: transparent;
    border: 1px solid #fff;
    border-radius: 4px;
    color: #fff;
    cursor: pointer;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    margin-right: 0;
    padding: 7px 16px;
    position: relative;
    text-decoration: none;
    z-index: 1;
}

.headerButton:hover {
    background-color: #003d78;
}

.colorWhite {
    color: #fff;
    text-decoration: none;
}

.colorWhite:visited {
    color: #fff;
}
