@import 'node_modules/nhsuk-frontend/packages/nhsuk';

@media print {
    .nhsuk-width-container.nhsuk-header__container {
        margin-top: 0 !important;
        margin-bottom: 0 !important;
        padding-top: 0 !important;
        padding-bottom: 0 !important;
        margin: 0 32px !important;
    }

    h1 {
        font-size: 1rem !important;
        margin-top: 5px !important;
        margin-bottom: 5px !important;
    }

    h2 {
        font-size: 0.8rem !important;
        break-after: avoid !important;
    }

    dt,
    dd,
    table td,
    table th {
        font-size: 0.6rem !important;
    }

    .nhsuk-main-wrapper {
        padding-bottom: 10px !important;
    }

    button {
        display: none !important;
    }

    .nhsuk-header__transactional-service-name--link[href] {
        &:after,
        &:before {
            content: '' !important;
        }
    }

    .nhsuk-summary-list__row {
        page-break-inside: avoid !important;
    }

    .nhsuk-summary-list__actions {
        display: none !important;
    }

    .leaflet-control-attribution.leaflet-control {
        display: none !important;
    }
}

.nhsuk-summary-list__key,
.nhsuk-summary-list__value,
.nhsuk-summary-list__actions {
    vertical-align: top;
}
